import { instanceOf } from 'prop-types';
import StringHelper from './StringHelper';

var moment = require('moment');
require('moment/locale/fr');


export default class DateHelper
{
    static SUNDAY = 0;  
    static MONDAY = 1;
    static TUESDAY = 2;
    static WEDNESDAY= 3;
    static THURSDAY = 4;
    static FRIDAY = 5; 
    static SATURDAY = 6;

    static nowAsTime()
    {
        const today = new Date();
        const hours = today.getHours();
        const minutes = today.getMinutes();
        return (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes;
    }

    static secondsAsHms(seconds)
    {
        var hours = parseInt(seconds / 3600);
        var minutes = parseInt( (seconds - 3600 * hours) / 60);
        var seconds = seconds - (60 * minutes) - (3600 * hours);

        return {
            hours: hours,
            minutes: minutes,
            seconds: seconds
        }
    }


    static dateAsMoment(dateValue)
    {
        if (typeof dateValue === "string")
        {
            return moment(dateValue)
        }
        else if (dateValue instanceof Date)
        {
            return moment(dateValue)
        }
        else if (dateValue instanceof moment)
        {
            return dateValue;
        }
        else if (dateValue.date)
        {
            return this.dateAsMoment(dateValue.date)
        }
        else
        {
            return null;
        }
    }

    static toString(date, pattern)
    {
        var moment1 = this.dateAsMoment(date);

        if (moment1)
        {
            return moment1.format(pattern)
        }
        else
        {
            return null;
        }
    }

    static getDayOfWeek(date)
    {
        var moment1 = this.dateAsMoment(date)
        if (moment1 != null)
        {
            const position = moment1.isoWeekday()
            const englishDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
            return {
                position: position, 
                english: englishDays[(position - 1)],
                year: moment1.year(),
                month: moment1.month()
            }
        }
        else
        {
            return null;
        }
    }

    static equals(date1, date2)
    {
        var moment1 = this.dateAsMoment(date1);
        var moment2 = this.dateAsMoment(date2);

        return moment1.format('YYYY-MM-DD') == moment2.format('YYYY-MM-DD')
        return false;
    }

    static getDatesBetween(startDate, endDate)
    {
        if (!startDate || !endDate)
        {
            return []
        }

        var dates = []
        var currentDate = startDate;

        while(currentDate <=  endDate)
        {
            dates.push(currentDate)
            currentDate = this.addDaysToDate(1, currentDate);
        }

        return dates;
    }

    static addDaysToDate(days, date = null)
    {
        var workingDate = !date ? new Date() : date;
        return moment(workingDate).clone().add(days, 'day').toDate();
    }

    static addMontToDate(months, date = null)
    {
        var workingDate = !date ? new Date() : date;
        return moment(workingDate).clone().add(months, 'M').toDate();
    }

    static findSpecificDaysBetween2Dates(from, to, days)
    {
        var matches = []

        var dates = [];

        var currDate = moment(from).startOf('day');
        var lastDate = moment(to).startOf('day');
        var nbDays = 0;

        while(currDate.add(nbDays, 'days').diff(lastDate) <= 0) {
            
            if (days.includes(currDate.day()))
            {
                dates.push(currDate.clone().toDate());
            }

            nbDays = 1;
        }

        return dates;
    }

    static findDaysBetween2Dates(from, to, exclusions = [])
    {
        var dates = [];
        var currDate = moment(from).startOf('day');
        var lastDate = moment(to).startOf('day');
        var nbDays = 0;

        while(currDate.add(nbDays, 'days').diff(lastDate) <= 0) {
            
            // TODO Gérer les jours fériés au format text ???
            if (!exclusions.includes(currDate.day()))
            {
                dates.push(currDate.clone().toDate());
            }

            nbDays = 1;
        }
        return dates;

        /*
        var matches = []
        
        var momDay = null;
        while(from <= to)
        {
            momDay = moment(from).startOf('day');
            if (!exclusions.includes(momDay.day()))
            {
                matches.push(from)
            }

            from = DateHelper.addDaysToDate(1, from)
        }

        return matches;*/
    }

    static now()
    {
        return moment();
    }

    static toMysqlDateFormat(date)
    {
        var dateFormat = "YYYY-MM-DD";
        return moment(date).format(dateFormat);
    }

    static mysqlDateToJavascript(myslDate)
    {
        var t = myslDate.split(/[-]/);

        // Apply each element to the Date function
        return new Date(Date.UTC(t[0], t[1]-1, t[2], 0, 0, 0));
    }

    static phpDateToJavascript(phpDate)
    {
        var date = phpDate;
        if (typeof phpDate.date !== undefined && phpDate.date != null)
        {
            date = phpDate.date;
        }
        
        const yyyymmdd_hhmmssPattern = /([0-9]{4})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])/;
        const yyyymmdd = /([0-9]{4})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/;
        var m = null;
        
        if ((m = yyyymmdd_hhmmssPattern.exec(date)) !== null) {
            //console.log(m)
            return new Date(m[1], m[2] - 1, m[3], m[4], m[5], m[6])
        }
        else if ((m = yyyymmdd.exec(date)) !== null) {
            return new Date(m[1], m[2] - 1, m[3], 0, 0, 0)
        }
        else
        {
            return new Date(date)
        }
    }

    static formatWithoutTime(date, intl = null)
    {
        var dateFormat = "DD/MM/YYYY";
        
        if (intl != null)
        {
            dateFormat = intl.formatMessage({id: "Default.day_month_year_format"})
        }

        return moment(date).format(dateFormat);
    }

    static firstDateOfMonth(date = null)
    {
        if (date == null)
        {
            date = moment();
        }
        return new Date(date.year(), date.months(), 1);
    }

    static lastDateOfMonth(date = null)
    {
        if (date == null)
        {
            date = moment();
        }

        return new Date(date.year(), date.months() + 1, 0);
    }

    static dateAsString(date, pattern = "DD/MM") {
        var result = this.getDayOfWeek(date)
        if(result.english) {
            var dayOfWeek = this.getDayOfWeek(date).english
        } else {
            var dayOfWeek = this.getDayOfWeek(date)
        }
        if(!!dayOfWeek) {
            return StringHelper.tryTranslate(this, "Default." + dayOfWeek, "").toLowerCase() + " " + this.toString(date, pattern);
        } else {
            return ""
        }
    }

    static datesAsStringPeriod(dates)
    {
        if (!dates || dates.length == 0)
            return "";

        // Conversion des dates et tri de tableau
        var workingDates = dates.map(d => {
            return this.phpDateToJavascript(d);
        })
        
        workingDates = workingDates.sort(function(a, b){
            var x = DateHelper.toMysqlDateFormat(a); 
            var y = DateHelper.toMysqlDateFormat(b);
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        })
        
        // On détermine les différentes périodes contenues dans les dates fournies
        var periods = [];
        var previousDate = null;
        var currentPeriod = []
        workingDates.forEach((date, index) => {
            if (previousDate == null || this.findDaysBetween2Dates(previousDate, date).length  > 2)
            {
                // Fin de la période précédente
                if (currentPeriod.length > 0)
                {
                    periods.push([...currentPeriod])
                }

                // Création d'un nouvelle periode
                currentPeriod = [date]
            }
            else  // Sinon ajout de la date à la période
            {
                currentPeriod.push(date)
            }
            previousDate = date
        })

        if (currentPeriod.length > 0)
        {
            periods.push(currentPeriod)
        }

        if (periods.length == 1 && periods[0].length == 1)
        {
            const uniquePeriod = periods[0]
            var date = uniquePeriod[0]
            var dayOfWeek = this.getDayOfWeek(date).english
                
            return workingDates.length + " jour le " + StringHelper.tryTranslate(this, "Default." + dayOfWeek, "").toLowerCase() + " " + this.toString(date, "DD/MM");
        }
        else
        {
            var datePattern = workingDates[0].getYear() == workingDates[workingDates.length - 1].getYear() ? "DD/MM" : "DD/MM/YYYY";

            var value = workingDates.length + " jours ";
            periods.forEach((p, index) => {
                var startDate = p[0]
                var startDayOfWeek = this.getDayOfWeek(startDate).english

                var endDate = p[p.length - 1]
                var endDayOfWeek = this.getDayOfWeek(endDate).english

                if (index > 0)
                {
                    value += " puis ";
                }

                if (p.length == 1)
                {
                    value += "le " + StringHelper.tryTranslate(this, "Default." + startDayOfWeek, "").toLowerCase() + " " + this.toString(startDate, datePattern)
                }
                else {
                    value += "du " + 
                        StringHelper.tryTranslate(this, "Default." + startDayOfWeek, "").toLowerCase() + " " + this.toString(startDate, datePattern) + 
                        " au " + 
                        StringHelper.tryTranslate(this, "Default." + endDayOfWeek, "").toLowerCase() + " " + this.toString(endDate, datePattern)
                }
            })            

            return value;
        }
    }

    static calculateBrutDaysRemaining(dateString) {
        // Convertir la date actuelle et la date donnée en objets Date
        const today = new Date()
        const givenDate = new Date(dateString)
        today.setHours(0)
        today.setMinutes(0)
        today.setSeconds(0)
        givenDate.setHours(0)
        givenDate.setMinutes(0)
        givenDate.setSeconds(0)
        // Calculer la différence en millisecondes
        const differenceInTime = givenDate - today
        // Convertir la différence de temps de millisecondes en jours
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24))

        return differenceInDays
    }

    static calculateHoursRemaining(dateString, missionHour) {
        // Convertir la date actuelle et la date donnée en objets Date
        const now = new Date()
        const givenDate = new Date(dateString)
        if(typeof missionHour === "string") {
            let result = missionHour.split(":");
            givenDate.setHours(result[0]);
            givenDate.setMinutes(result[1]);
        } else {
            givenDate.setHours(7);
        }
        // Calculer la différence en millisecondes
        const differenceInTime = givenDate - now

        // Convertir la différence de temps de millisecondes en heures
        const differenceInHours = Math.ceil(differenceInTime / (1000 * 3600))

        return differenceInHours
    }
}