import React, { useEffect, useState } from "react"

const Search = ({ setStartOrder }) => {
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		const iframe = document.getElementById("iframeV2")
		if(iframe) {
			iframe.addEventListener("load", onLoad)
		}
	}, [])

	function onLoad() {
		setLoaded(true)
	}

	useEffect(() => {
		if (loaded) {
			let win = window.frames.iframeV2
			win.postMessage({
				token: localStorage.getItem("token")
			}, process.env.REACT_APP_FRONT_V2_DOMAIN)
		}
	}, [loaded])

	useEffect(() => {
		function receiveMessage(event) {
			if (event.origin !== process.env.REACT_APP_FRONT_V2_DOMAIN) return
			if(event.data.goToMain) {
				window.location.href = process.env.REACT_APP_WORDPRESS_MAIN_DOMAIN
			} else {
				setStartOrder(event.data)
			}
		}
		window.addEventListener("message", receiveMessage, false)

		// N'oubliez pas de supprimer l'écouteur d'événements lorsque le composant est démonté
		return () => {
			window.removeEventListener("message", receiveMessage, false)
		}
	}, [])

	useEffect(() => {
		if (loaded) {
			let win = window.frames.iframeV2
			win.postMessage({
				token: localStorage.getItem("token")
			}, process.env.REACT_APP_FRONT_V2_DOMAIN)
		}
	}, [loaded])

	return (
		<div className="iframe-wrapper"
				 style={{ height: "99vh"}}
		>
			{!loaded &&
          <div className="loader">
              Chargement...
          </div>
			}
			<iframe
				id='iframeV2'
				style={{ width: "100%", height: "100%", border: "none" }}
				className="iframe-V2"
				src={`${process.env.REACT_APP_FRONT_V2_DOMAIN}/provision`}
				name="iframeV2"
			/>
		</div>

	)
}

export default Search